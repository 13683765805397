/**
 * Initialize Vue
 */
import Vue from 'vue'
import MessageFormat from 'messageformat'

class CustomFormatter {
  constructor(options = {}) {
    this._locale = options.locale || 'en'
    this._formatter = new MessageFormat(this._locale)
    // this._formatter.setIntlSupport(true)
    this._caches = Object.create(null)
  }

  interpolate(message, values) {
    let fn = this._caches[message]
    if (!fn) {
      fn = this._formatter.compile(message, this._locale)
      this._caches[message] = fn
    }
    return [fn(values)]
  }
}

/**
 * Locales
 */
import VueI18n from 'vue-i18n'
import Locales from './vendor/vue-i18n-locales.generated.js'

Vue.use(VueI18n)

export function createLocales(locale) {
  const formatter = new CustomFormatter({ locale })

  return new VueI18n({
    locale,
    formatter,
    messages: Locales
  })
}
