import '../fontawesome'
import 'pwstrength-bootstrap/dist/pwstrength-bootstrap'
// import swal from 'sweetalert2'
// import WebFont from 'webfontloader'
import Turbolinks from 'turbolinks'

/**
 * JS Settings App
 */
let jsonSettings = document.querySelector('[data-settings-selector="settings-json"]')
window.settings = jsonSettings ? JSON.parse(jsonSettings.textContent) : {}

// window.swal = swal
window.locale = $('html').attr('lang')

export default createApp => {
  Turbolinks.start()

  /**
   * Font
   */
  /* WebFont.load({
    google: {
      families: ['Roboto']
    }
  }) */

  /**
   * Cookie Consent
   */
  /* window.addEventListener('load', () => {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#fff',
          text: '#777'
        },
        button: {
          background: '#3097d1',
          text: '#ffffff'
        }
      },
      showLink: false,
      theme: 'edgeless',
      content: {
        message: window.settings.cookieconsent.message,
        dismiss: window.settings.cookieconsent.dismiss
      }
    })
  }) */

  document.addEventListener('turbolinks:load', () => {
    // turn off preloader
    const preloader = document.getElementById('preloader')
    if (preloader !== null) {
      preloader.style.display = 'none'
    }

    /**
     * Vue Mounting
     */
    if (document.getElementById('app') !== null) {
      const { app } = createApp()
      app.$mount('#app')
    }

    /**
     * Bind all bootstrap tooltips
     */
    // $('[data-toggle="tooltip"]').tooltip()

    /**
     * Bind all bootstrap popovers
     */
    // $('[data-toggle="popover"]').popover()

    $('[data-toggle="password-strength-meter"]').pwstrength({
      ui: {
        bootstrap4: true
      }
    })

    /**
     * Bootstrap tabs nav specific hash manager
     */
    let hash = document.location.hash
    let tabanchor = $('.nav-tabs a:first')

    if (hash) {
      tabanchor = $(`.nav-tabs a[href="${hash}"]`)
    }

    tabanchor.tab('show')

    $('a[data-toggle="tab"]').on('show.bs.tab', e => {
      window.location.hash = e.target.hash
    })
  })
}
